import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
import { window } from "browser-monads"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { Container } from "@material-ui/core"

const useStyles = makeStyles(
  theme =>
    createStyles({
      nav: {
        '& [aria-current]:not([aria-current="false"])': {
          backgroundColor: "#e12425",
          color: "white"
        },
        padding: 0,
        margin: 0,
        display: "flex",
        justifyContent: "space-evenly",
        listStyleType: "none",
        "& li": {
          width: "-webkit-fill-available",
          textAlign: "center"
        },
        "& li > a": {
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          color: "#282828",
          display: "block",
          fontWeight: "900",
          textTransform: "uppercase",
          padding: 20,
          transition: "400ms ease",
          MozTransition: "none",
          transform: "skewX(-10deg)",
          borderLeft: "2px solid #181818",
          overflow: "hidden",
          backgroundColor: "white",
          outline: "none"
        },
        "& li > a::before": {
          width: 30,
          height: 150,
          background: "#181818",
          position: "absolute",
          right: "-30px",
          top: "-30px",
          left: "auto",
          content: "''",
          transition: "right 0.4s ease",
          transitionDelay: "0.15s",
          WebkitTransitionDelay: "0.15s",
          visibility: "hidden"
        },
        "& li > a::after": {
          width: 20,
          height: 150,
          background: "#181818",
          position: "absolute",
          right: "-30px",
          top: "-30px",
          left: "auto",
          content: "''",
          transition: "right 0.4s ease",
          visibility: "hidden",
          MozTransition: "none"
        },
        "& li > a:hover": {
          backgroundColor: "#E12425",
          color: "white",
          transition: "0.4s ease"
        },
        "& li > a:hover::after": {
          right: "110%",
          visibility: "visible"
        },
        "& li > a:hover::before": {
          right: "110%",
          visibility: "visible"
        }
      },
      sticky: {
        transition: "400ms ease",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: "9999999"
      }
    }),
  { name: "MuiDesktopNav", index: 1 }
)

const DesktopNav = ({ navLinks, pathname }) => {
  const classes = useStyles()
  const fixedSize = 14
  const fixedPadding = 14
  const [scrolled, setScrolled] = useState(false)
  const isMounted = useRef(false)

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 1) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    isMounted.current = true
    if (isMounted) {
      window.addEventListener("scroll", handleScroll)
    }
    return () => (isMounted.current = false)
  })

  return (
    <Container className={scrolled ? classes.sticky : "placeholder"}>
      <nav
        style={{
          marginTop: scrolled && 0
        }}
      >
        <ul className={classes.nav}>
          {navLinks.map(n => (
            <li key={n.to}>
              <Link
                aria-current={
                  n.index === "home" && pathname === "/"
                    ? true
                    : n.index === pathname
                    ? true
                    : false
                }
                to={n.to}
                style={{
                  fontSize: scrolled && fixedSize,
                  paddingTop: scrolled && fixedPadding,
                  paddingBottom: scrolled && fixedPadding
                }}
              >
                <span>{n.name}</span>
              </Link>
            </li>
          ))}
          <li>
            <Link
              to="https://www.start.gg/tournament/kumite-in-tennessee-2022-the-finale/details"
              style={{
                fontSize: scrolled && fixedSize,
                paddingTop: scrolled && fixedPadding,
                paddingBottom: scrolled && fixedPadding
              }}
            >
              <span>Register</span>
            </Link>
          </li>
          <li>
            <Link
              to="https://reservations.arestravel.com/hotel/list/14884/i5842?poiDistance=30"
              style={{
                fontSize: scrolled && fixedSize,
                paddingTop: scrolled && fixedPadding,
                paddingBottom: scrolled && fixedPadding
              }}
            >
              <span>Hotels</span>
            </Link>
          </li>
          <li>
            <Link
              to="https://docs.google.com/spreadsheets/d/1tAdbM-CI2veE92FccrPo2YmmDkxwXNm1P9yCLe6LzB0/edit?usp=sharing"
              style={{
                fontSize: scrolled && fixedSize,
                paddingTop: scrolled && fixedPadding,
                paddingBottom: scrolled && fixedPadding
              }}
            >
              <span>Schedule</span>
            </Link>
          </li>
          <li>
            <Link
              to="https://docs.google.com/spreadsheets/d/1C7hfDmcQdT2TzQp29OvVW0kgp-HEdJMcloY08slo2qY/edit?usp=sharing"
              style={{
                fontSize: scrolled && fixedSize,
                paddingTop: scrolled && fixedPadding,
                paddingBottom: scrolled && fixedPadding
              }}
            >
              <span>Sale</span>
            </Link>
          </li>
          {/* <li>
            <Link
              to="https://docs.google.com/spreadsheets/d/1afVt2vA4bJjJhOCsqd1XpYcKWG5AMIyrZAJJk0ntghw/edit?usp=sharing"
              style={{
                fontSize: scrolled && fixedSize,
                paddingTop: scrolled && fixedPadding,
                paddingBottom: scrolled && fixedPadding
              }}
            >
              <span>Schedule</span>
            </Link>
          </li> */}
        </ul>
      </nav>
    </Container>
  )
}

export default DesktopNav
