import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(
  theme =>
    createStyles({
      titleDefault: {
        margin: "0 0 50px 13px",
        display: "block",
        minHeight: 33,
        boxShadow: "#e12425 0 -1px 0 inset",
        overflow: "visible",
        position: "relative",
        "& > div": {
          height: 33,
          float: "left",
          textTransform: "uppercase",
          font: "800 14px/30px roboto",
          color: "white",
          position: "relative",
          zIndex: "999"
        },
        "& > div span": {
          zIndex: "100000",
          position: "relative",
          display: "inline-block",
          padding: "0 15px"
        },
        "& div::after": {
          left: 3,
          right: 3,
          top: 0,
          height: "100%",
          content: "''",
          position: "absolute",
          transform: "skewX(-10deg)",
          zIndex: "10000",
          background: "#e12425"
        }
      },
      more: {
        marginLeft: "-7px",
        font: "800 12px/33px roboto",
        position: "relative",
        display: "block",
        float: "left",
        textTransform: "uppercase",
        padding: "0 15px",
        overflow: "hidden",
        color: "#e12425",
        transition: "400ms ease",
        "&::after": {
          left: 3,
          right: 3,
          height: "100%",
          content: "''",
          position: "absolute",
          transform: "skewX(-10deg)",
          zIndex: "10",
          background: "transparent",
          border: "1px solid #e12425",
          transition: "400ms ease",
          overflow: "hidden"
        },
        "& span": {
          zIndex: "100",
          position: "relative"
        },
        "&:hover::after": {
          backgroundColor: "#fff",
          border: "1px solid #fff",
          color: "black"
        },
        "&:hover": {
          color: "black"
        }
      }
    }),
  { name: "MuiSectionTitle", index: 1 }
)

export default function EventList({ title, navigate }) {
  const classes = useStyles()

  return (
    <>
      <div className={classes.titleDefault}>
        <div>
          <span>{title}</span>
        </div>
        {navigate && (
          <Link to={navigate} className={classes.more}>
            <span>View All</span>
          </Link>
        )}
      </div>
    </>
  )
}

EventList.propTypes = {
  title: PropTypes.string.isRequired,
  navigate: PropTypes.string
}
