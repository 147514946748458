import React from "react"
import useWindowSize from "../../../utils/useWindowSize"
import DesktopNav from "./DesktopNav"
import MobileNav from "./MobileNav"

const navLinks = [
  {
    index: "home",
    to: "/",
    name: "Home"
  },
  {
    index: "/news",
    to: "/news",
    name: "News"
  },
  // {
  //   index: "/policies",
  //   to: "/policies",
  //   name: "Policies"
  // },
  {
    index: "/rules",
    to: "/rules",
    name: "Rules"
  }
  // {
  //   index: "/sidegames",
  //   to: "/sidegames",
  //   name: "Side Games"
  // },
  // {
  //   index: "/cosplay",
  //   to: "/cosplay",
  //   name: "Cosplay"
  // }
]

const Nav = ({ pathname }) => {
  const { width } = useWindowSize()

  return (
    <div>
      {width >= 600 && <DesktopNav pathname={pathname} navLinks={navLinks} />}
      {width < 600 && <MobileNav pathname={pathname} navLinks={navLinks} />}
      {/* <MobileNav pathname={pathname} navLinks={navLinks} /> */}
    </div>
  )
}

export default Nav
