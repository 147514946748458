import React from "react"
import { Link } from "gatsby"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { Fade } from "react-reveal"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton } from "@material-ui/core"

const useStyles = makeStyles(
  theme =>
    createStyles({
      nav: {
        height: "100vh",
        maxWidth: 600,
        background: "#282828",
        '& [aria-current]:not([aria-current="false"])': {
          backgroundColor: "#e12425",
          color: "white"
        },
        padding: 0,
        margin: 0,
        listStyleType: "none",
        "& > li": {
          width: "-webkit-fill-available",
          textAlign: "center"
        },
        "& > li > a": {
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          background: "white",
          display: "block",
          fontWeight: "900",
          textTransform: "uppercase",
          padding: 20,
          transition: "400ms ease",
          MozTransition: "none",
          borderLeft: "2px solid #181818",
          overflow: "hidden"
        },
        "& a": {
          color: "#282828",
          outline: "none"
        },
        "& > li > a::before": {
          width: 30,
          height: 150,
          background: "#181818",
          position: "absolute",
          right: "-30px",
          top: "-30px",
          left: "auto",
          content: "''",
          transition: "right 0.4s ease",
          transitionDelay: "0.15s",
          WebkitTransitionDelay: "0.15s",
          visibility: "hidden"
        },
        "& > li > a::after": {
          width: 20,
          height: 150,
          background: "#181818",
          position: "absolute",
          right: "-30px",
          top: "-30px",
          left: "auto",
          content: "''",
          transition: "right 0.4s ease",
          MozTransition: "none",
          visibility: "hidden"
        },
        "& > li > a:hover": {
          backgroundColor: "#E12425",
          color: "white",
          transition: "0.4s ease"
        },
        "& > li > a:hover::after": {
          right: "110%",
          visiability: "visible"
        },
        "& > li > a:hover::before": {
          right: "110%",
          visiability: "visible"
        }
      },
      stickyBottom: {
        transition: "400ms ease",
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: "999999",
        width: "100vw"
      },
      active: {
        backgroundColor: "#e12425",
        color: "white"
      },
      button: {
        marginLeft: "auto",
        border: "none",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        background: "#e12425",
        display: "block",
        fontWeight: "900",
        textTransform: "uppercase",
        padding: 20,
        transition: "400ms ease",
        MozTransition: "none",
        overflow: "hidden",
        color: "#282828",
        "& span": {
          fontFamily: '"open sans", roboto, sans-serif'
        }
      }
    }),
  { name: "MuiMobileNav", index: 1 }
)

const MobileNav = ({ navLinks, pathname }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const fixedSize = 15
  const fixedPadding = 15

  return (
    <>
      <nav
        style={{
          marginBottom: 0
        }}
        className={classes.stickyBottom}
      >
        <Fade top collapse when={open === true}>
          <ul className={classes.nav}>
            {navLinks.map(n => (
              <li key={n.to}>
                <Link
                  aria-current={
                    n.index === "home" && pathname === "/"
                      ? true
                      : n.index === pathname
                      ? true
                      : false
                  }
                  to={n.to}
                  style={{
                    fontSize: fixedSize,
                    paddingTop: fixedPadding,
                    paddingBottom: fixedPadding
                  }}
                >
                  <span>{n.name}</span>
                </Link>
              </li>
            ))}
            <li>
              <Link
                to="https://www.start.gg/tournament/kumite-in-tennessee-2022-the-finale/details"
                style={{
                  fontSize: fixedSize,
                  paddingTop: fixedPadding,
                  paddingBottom: fixedPadding
                }}
              >
                <span>Register</span>
              </Link>
            </li>
            <li>
              <Link
                to="https://reservations.arestravel.com/hotel/list/14884/i5842?poiDistance=30"
                style={{
                  fontSize: fixedSize,
                  paddingTop: fixedPadding,
                  paddingBottom: fixedPadding
                }}
              >
                <span>Hotels</span>
              </Link>
            </li>
            <li>
              <Link
                to="https://docs.google.com/spreadsheets/d/1tAdbM-CI2veE92FccrPo2YmmDkxwXNm1P9yCLe6LzB0/edit?usp=sharing"
                style={{
                  fontSize: fixedSize,
                  paddingTop: fixedPadding,
                  paddingBottom: fixedPadding
                }}
              >
                <span>Schedule</span>
              </Link>
            </li>
            <li>
              <Link
                to="https://docs.google.com/spreadsheets/d/1C7hfDmcQdT2TzQp29OvVW0kgp-HEdJMcloY08slo2qY/edit?usp=sharing"
                style={{
                  fontSize: fixedSize,
                  paddingTop: fixedPadding,
                  paddingBottom: fixedPadding
                }}
              >
                <span>Sale</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="https://docs.google.com/spreadsheets/d/1afVt2vA4bJjJhOCsqd1XpYcKWG5AMIyrZAJJk0ntghw/edit?usp=sharing"
                style={{
                  fontSize: fixedSize,
                  paddingTop: fixedPadding,
                  paddingBottom: fixedPadding
                }}
              >
                <span>Schedule</span>
              </Link>
            </li> */}
            <IconButton
              style={{
                width: "100%",
                margin: "10px auto 0"
              }}
              onClick={() => setOpen(!open)}
            >
              <CloseIcon style={{ color: "white", fontSize: "3.5rem" }} />
            </IconButton>
          </ul>
        </Fade>
      </nav>
      <button
        className={classes.button}
        style={{
          fontSize: fixedSize,
          padding: fixedPadding,
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: "999998",
          cursor: "pointer"
        }}
        onClick={() => setOpen(!open)}
      >
        <MenuIcon style={{ color: "white" }} />
      </button>
    </>
  )
}

export default MobileNav
